import Assistive from "./Assistive";
import ErrorText from "./ErrorText";
import Label, { LabelText } from "./Label";
import Legend from "./Legend";

export default {
  Assistive,
  ErrorText,
  Label,
  LabelText,
  Legend,
};
