import React from "react";
import { DeclaritiveFormProps, SchemaField } from "./DeclaritiveForm.types";
import classnames from "classnames";
import FormGroup, { FormGroupControls } from "./Groups/FormGroup";
import Label, { LabelText } from "../../Atoms/Controls/Labels/Label";
import Text from "../../Atoms/Controls/Text/Text";
import Assistive from "../../Atoms/Controls/Labels/Assistive";
import Slug from "../../Atoms/Controls/Slug/Slug";
import { useFormik } from "formik";
import * as yup from "yup";
import ErrorText from "../../Atoms/Controls/Labels/ErrorText";
import Textarea from "../../Atoms/Controls/Textarea/Textarea";
import Button from "../../Atoms/Button/Button";
import Spinner from "../../Atoms/Spinner/Spinner";
import Switch from "../../Atoms/Controls/Switch/Switch";
import { FormGroupType } from "./Groups/FormGroup.types";
import Icon from "../../Atoms/Icon/Icon";
import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";
import Select from "../../Atoms/Controls/Select/Select";
import Legend from "../../Atoms/Controls/Labels/Legend";
import Checkbox from "../../Atoms/Controls/Checkbox/Checkbox";
import { toHtmlId } from "@gbg/gbgcomponentlibrary/src/_utilities";
import Radio from "../../Atoms/Controls/Radio/Radio";

export const DeclaritiveForm: React.FC<DeclaritiveFormProps> = React.forwardRef<
  HTMLFormElement,
  DeclaritiveFormProps
>(
  (
    { children, data, isLoading, schema, onSubmit, submitText, ...props },
    ref
  ) => {
    const formClasses = classnames("form");

    const initialValues = Object.keys(schema)
      .map((k) => ({
        [k]: data?.[k] ?? (schema[k].isArray ? [] : ""),
      }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {});

    const validationObject = Object.entries(schema)
      .filter(([, v]) => v.validation)
      .map(([key, schemaEntry]) => ({
        [key]: schemaEntry.validation,
      }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {});

    const {
      handleSubmit,
      handleChange,
      handleBlur,
      isSubmitting,
      touched,
      values, // use this if you want controlled components
      errors,
      setFieldValue,
    } = useFormik({
      initialValues: initialValues,
      validationSchema: yup.object().shape(validationObject),
      onSubmit: (values) => {
        onSubmit?.(values);
      },
    });

    const renderControl = (key: string, field: SchemaField) => {
      if (field.node) {
        return field.node((value) => {
          console.log(value);
        });
      }
      switch (field.dataType) {
        case "string":
          return renderTextField(key, field);
        case "longstring":
          return renderTextAreaField(key, field);
        case "number":
          return renderTextField(key, field, { type: "number" });
        case "boolean":
          return renderCheckboxField(key, field);
        case "discretevalue":
          return field.isArray
            ? renderCheckboxList(key, field)
            : field.options.length > 3
            ? renderSelectField(key, field)
            : renderRadioList(key, field);
        case "hidden":
          return renderHiddenField(key, field);
        default:
          return null;
      }
    };

    const renderCheckboxList = (
      key: string,
      field: SchemaField,
      attrOverides?: any
    ) => {
      const attrs = {
        name: key,
        //defaultChecked: values[ke,
        error: errors[key] && touched[key],

        // onChange: handleChange,
        // onBlur: handleBlur,
      };

      return (
        <FormGroupControls>
          {(field.options as string[]).map((option) => (
            <FormGroup
              key={toHtmlId(`${key}_${option}`)}
              type={FormGroupType.Checkbox}
            >
              <Checkbox
                defaultChecked={values[key].indexOf(option) > -1}
                onChange={(evt) => {
                  setFieldValue(
                    key,
                    evt.target.checked
                      ? [...values[key], option]
                      : values[key].filter((v) => v !== option)
                  );
                }}
                id={toHtmlId(`${key}_${option}`)}
                {...attrs}
              />
              <Label htmlFor={toHtmlId(`${key}_${option}`)} inline={true}>
                {option}
              </Label>
            </FormGroup>
          ))}
        </FormGroupControls>
      );
    };

    const renderRadioList = (
      key: string,
      field: SchemaField,
      attrOverides?: any
    ) => {
      const attrs = {
        name: key,
        error: errors[key] && touched[key],
        onChange: handleChange,
        onBlur: handleBlur,
      };

      return (
        <FormGroupControls>
          {(field.options as string[]).map((option) => (
            <FormGroup
              key={toHtmlId(`${key}_${option}`)}
              type={FormGroupType.Checkbox}
            >
              <Radio
                id={toHtmlId(`${key}_${option}`)}
                defaultChecked={values[key] == option}
                value={option}
                {...attrs}
              />
              <Label htmlFor={toHtmlId(`${key}_${option}`)} inline={true}>
                {option}
              </Label>
            </FormGroup>
          ))}
        </FormGroupControls>
      );
    };

    const renderSelectField = (
      key: string,
      field: SchemaField,
      attrOverides?: any
    ) => {
      const attrs = {
        id: key,
        name: key,
        defaultValue: values[key],
        error: errors[key] && touched[key],
        onChange: handleChange,
        onBlur: handleBlur,
      };

      return (
        <Select {...field.controlProps} {...attrs} {...attrOverides}>
          {field.options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
      );
    };

    const renderCheckboxField = (
      key: string,
      field: SchemaField,
      attrOverides?: any
    ) => {
      const attrs = {
        id: key,
        name: key,
        checked: values[key],
        error: errors[key] && touched[key],
        onChange: handleChange,
        onBlur: handleBlur,
      };

      return <Switch {...field.controlProps} {...attrs} {...attrOverides} />;
    };

    const renderTextField = (
      key: string,
      field: SchemaField,
      attrOverides?: any
    ) => {
      const attrs = {
        id: key,
        defaultValue: values[key],
        name: key,
        error: errors[key] && touched[key],
        onChange: handleChange,
        onBlur: handleBlur,
      };
      if (field.slug) {
        return (
          <Slug
            {...field.controlProps}
            {...attrs}
            slug={field.slug}
            {...attrOverides}
          />
        );
      }
      return <Text {...field.controlProps} {...attrs} {...attrOverides} />;
    };

    const renderHiddenField = (
      key: string,
      field: SchemaField,
      attrOverides?: any
    ) => {
      const attrs = {
        id: key,
        defaultValue: values[key],
        name: key,
        error: errors[key] && touched[key],
        onChange: handleChange,
        onBlur: handleBlur,
      };
      return (
        <input
          {...field.controlProps}
          {...attrs}
          {...attrOverides}
          type="hidden"
        />
      );
    };

    const renderTextAreaField = (
      key: string,
      field: SchemaField,
      attrOverides?: any
    ) => {
      const attrs = {
        id: key,
        name: key,
        defaultValue: values[key],
        error: errors[key] && touched[key],
        onChange: handleChange,
        onBlur: handleBlur,
      };

      return <Textarea {...field.controlProps} {...attrs} {...attrOverides} />;
    };

    const renderFormGroup = (k: string, f: SchemaField) => {
      return (
        <FormGroup
          style={{
            maxWidth: f.maxWidth ?? "100%",
          }}
          key={k}
          type={
            f.dataType == "boolean"
              ? FormGroupType.Checkbox
              : f.dataType == "discretevalue" && f.isArray
              ? FormGroupType.CheckboxList
              : FormGroupType.Standard
          }
        >
          {f.dataType === "boolean" ? renderControl(k, f) : null}
          {f.dataType === "discretevalue" && f.isArray ? (
            <Legend>
              <LabelText>
                {f.label ?? k}
                {f.isRequired ||
                f?.validation?.tests.find((t) => t.OPTIONS.name == "required")
                  ? "*"
                  : null}
              </LabelText>
              {f.helpText ? (
                <Icon
                  title={f.helpText}
                  icon={IconKeys.HelpCircle}
                  className="text-b800 icon--smaller"
                ></Icon>
              ) : null}
            </Legend>
          ) : (
            <Label htmlFor="input1">
              <LabelText>
                {f.label ?? k}
                {f.isRequired ||
                f?.validation?.tests.find((t) => t.OPTIONS.name == "required")
                  ? "*"
                  : null}
              </LabelText>
              {f.helpText ? (
                <Icon
                  title={f.helpText}
                  icon={IconKeys.HelpCircle}
                  className="text-b800 icon--smaller"
                ></Icon>
              ) : null}
            </Label>
          )}
          {f.assistiveText && <Assistive>{f.assistiveText}</Assistive>}
          {f.dataType !== "boolean" ? renderControl(k, f) : null}
          {touched[k] && errors[k] ? <ErrorText>{errors[k]}</ErrorText> : null}
        </FormGroup>
      );
    };

    if (isLoading) {
      return <Spinner />;
    }

    const hStackIgnore: string[] = [];

    return (
      <form
        className={formClasses}
        {...props}
        ref={ref}
        onSubmit={handleSubmit}
      >
        {Object.entries(schema)
          .filter(([k, f]) => f.dataType != "hidden")
          .map(([k, f]) => {
            if (f.hstack) {
              if (hStackIgnore.indexOf(f.hstack) > -1) {
                return null;
              }
              hStackIgnore.push(f.hstack);
              return (
                <FormGroup className="form-group--hstack">
                  {Object.entries(schema)
                    .filter(([k, f2]) => f2.hstack == f.hstack)
                    .map(([k, f]) => {
                      return renderFormGroup(k, f);
                    })}
                </FormGroup>
              );
            } else {
              return renderFormGroup(k, f);
            }
          })}
        <FormGroup>
          <Button
            className="m-m-b-2"
            aria-label="submit-button"
            type="submit"
            worker={true}
            active={isSubmitting}
          >
            {submitText ?? "Submit"}
          </Button>
        </FormGroup>
        {Object.entries(schema)
          .filter(([k, f]) => f.dataType == "hidden")
          .map(([k, f]) => {
            renderControl(k, f);
          })}
      </form>
    );
  }
);

export default DeclaritiveForm;
