import React, { useEffect } from "react";
import { ModalBodyProps, ModalHeaderProps, ModalProps } from "./Modal.types";
import classnames from "classnames";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./Modal.scss";

export const Modal: React.FC<ModalProps> = React.forwardRef<
  HTMLDivElement,
  ModalProps
>(({ fadeIn, isVisible, className, children, ...props }, ref) => {
  const modalClasses = classnames("modal", {}, className);

  useEffect(() => {
    if (isVisible) {
      document.body.classList.add("noscroll");
    } else {
      document.body.classList.remove("noscroll");
    }
  }, [isVisible]);

  return (
    <CSSTransition
      in={isVisible}
      classNames="modal"
      timeout={{ enter: 250, exit: 250 }}
    >
      <div className={modalClasses} {...props} ref={ref}>
        <div className="modal__overlay">
          <div className="modal__content">{children}</div>
        </div>
      </div>
    </CSSTransition>
  );
});

export const ModalBody: React.FC<ModalBodyProps> = React.forwardRef<
  HTMLDivElement,
  ModalBodyProps
>(({ className, children, ...props }, ref) => {
  const modalBodyClasses = classnames("modal__body", {}, className);
  return (
    <div className={modalBodyClasses} {...props} ref={ref}>
      {children}
    </div>
  );
});

export const ModalHeader: React.FC<ModalHeaderProps> = React.forwardRef<
  HTMLDivElement,
  ModalHeaderProps
>(({ className, children, ...props }, ref) => {
  const modalHeaderClasses = classnames("modal__header", {}, className);
  return (
    <div className={modalHeaderClasses} {...props} ref={ref}>
      {children}
    </div>
  );
});

export default Modal;
