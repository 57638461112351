import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { AddressFormProps } from "./AddressForm.types";
import FormGroup from "../../Molecules/Forms/Groups/FormGroup";
import Label from "../../Atoms/Controls/Labels/Label";
import Assistive from "../../Atoms/Controls/Labels/Assistive";
import Address from "../../Atoms/Controls/Address/Address";
import Modal, { ModalBody } from "../../Molecules/Modal/Modal";
import Button from "../../Atoms/Button/Button";
import Text from "../../Atoms/Controls/Text/Text";
import { ButtonKind } from "../../Atoms/Button/Button.types";
import Select from "../../Atoms/Controls/Select/Select";
import { countryData } from "@gbg/gbgcomponentlibrary/src/data/countries";

export const AddressForm = React.forwardRef<HTMLInputElement, AddressFormProps>(
  (
    {
      address: preAddress,
      captureKey,
      options,
      fields,
      resultCallback,
      className,
      icon,
      error,
      disabled,
      placeholder,
      type,
      ...props
    }: AddressFormProps,
    ref
  ) => {
    const addressClases = classnames("address-lookup-form", className);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const getLabel = (address: any) => {
      return [
        address.Company,
        address.Line1,
        address.Line2,
        address.Line3,
        address.City,
        address.PostalCode,
        getCountryName(address.CountryIso3),
      ].filter((i) => i && i.trim() != "");
    };

    const getCountryName = (countryCode: string) => {
      return countryData.find((c) => c["alpha-3"] === countryCode)?.name ?? "";
    };

    const [label, setLabel] = useState<string[]>(
      preAddress ? getLabel(preAddress) : []
    );
    const [address, setAddress] = useState<any>(preAddress);
    const [manualAddress, setManualAddress] = useState<any>(null);

    const handleAddressResponse = (address: any) => {
      setLabel(getLabel(address));
      setAddress(address);
    };

    const handleManualValueChange = (key: string) => (
      evt: ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
      setManualAddress({
        ...manualAddress,
        [key]: evt.target.value,
      });
    };

    return (
      <>
        <FormGroup {...props} className={addressClases}>
          <Label htmlFor="address_input">Address *</Label>
          <Assistive>Registered company address</Assistive>
          <Address
            id="address_input"
            className="m-m-b-2"
            captureKey={captureKey}
            resultCallback={(addressData, alts, key) => {
              handleAddressResponse(addressData);
              if (resultCallback) {
                resultCallback(addressData, alts, key);
              }
            }}
            placeholder={placeholder}
            disabled={disabled}
          ></Address>
          <p className="address-lookup__label m-m-b-2">
            {label.map((l, i) => (
              <React.Fragment key={i}>
                <span>{l}</span>
                <br />
              </React.Fragment>
            ))}
          </p>
          <a
            className="link"
            onClick={() => {
              setManualAddress(address);
              setModalVisible(true);
            }}
          >
            Enter Manually
          </a>
        </FormGroup>
        <Modal isVisible={modalVisible}>
          <ModalBody className="address__modal">
            <FormGroup>
              <Label htmlFor="address__company">Company</Label>
              <Text
                id="address__company"
                data-address-mapping="Company"
                type="text"
                value={manualAddress?.Company ?? ""}
                onChange={handleManualValueChange("Company")}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="address__line1">Line 1 *</Label>
              <Text
                id="address__line1"
                data-address-mapping="Line1"
                type="text"
                value={manualAddress?.Line1 ?? ""}
                onChange={handleManualValueChange("Line1")}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="address__line2">Line 2</Label>
              <Text
                id="address__line2"
                data-address-mapping="Line2"
                type="text"
                value={manualAddress?.Line2 ?? ""}
                onChange={handleManualValueChange("Line2")}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="address__line3">Line 3</Label>
              <Text
                id="address__line3"
                data-address-mapping="Line3"
                type="text"
                value={manualAddress?.Line3 ?? ""}
                onChange={handleManualValueChange("Line3")}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="address__town">Town *</Label>
              <Text
                id="address__town"
                data-address-mapping="City"
                type="text"
                value={manualAddress?.City ?? ""}
                onChange={handleManualValueChange("City")}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="address__postcode">Postcode *</Label>
              <Text
                id="address__postcode"
                data-address-mapping="PostalCode"
                type="text"
                value={manualAddress?.PostalCode ?? ""}
                onChange={handleManualValueChange("PostalCode")}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="address__country">Country *</Label>
              <Select
                name="address__country"
                data-address-mapping="CountryIso3"
                data-field-mode="COUNTRY"
                id="address__country"
                value={manualAddress?.CountryIso3 ?? ""}
                onChange={handleManualValueChange("CountryIso3")}
              >
                {countryData.map((country) => (
                  <option value={country["alpha-3"]} key={country["alpha-3"]}>
                    {country.name}
                  </option>
                ))}
              </Select>
            </FormGroup>
            <Button
              kind={ButtonKind.Secondary}
              onClick={() => {
                setModalVisible(false);
              }}
              className="m-m-t-4 m-m-r-1"
            >
              Cancel
            </Button>
            <Button
              className="m-m-t-4"
              onClick={() => {
                handleAddressResponse(manualAddress);
                setModalVisible(false);
              }}
            >
              Save address
            </Button>
          </ModalBody>
        </Modal>
      </>
    );
  }
);

export default AddressForm;
