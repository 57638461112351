import { IExtendableElement } from "../../Base/Components";
import { ButtonHTMLAttributes } from "react";
import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";

export interface ButtonProps
  extends IExtendableElement<ButtonHTMLAttributes<HTMLButtonElement>> {
  kind?: ButtonKind;
  iconBefore?: IconKeys | string;
  iconAfter?: IconKeys | string;
  iconContent?: IconKeys | string;
  size?: ButtonSize;
  worker?: boolean;
  workerPosition?: ButtonWorkerPosition;
  active?: boolean;
  inTable?: boolean;
}

export enum ButtonWorkerPosition {
  Centre = "centre",
  Left = "left",
  Right = "right",
}

export enum ButtonSize {
  Small = "small",
  Regular = "regular",
}

export enum ButtonKind {
  Primary = "primary",
  Secondary = "secondary",
  Tertiary = "tertiary",
  Destructive = "destructive",
  SecondaryDestructive = "secondarydestructive",
}
