import React from 'react'
import classnames from 'classnames'
import { CardBodyProps, CardHeaderProps, CardProps } from './Card.types';
import { getGridClasses } from '../Grid/Grid.types';




export const Card: React.FC<CardProps> = React.forwardRef<HTMLDivElement, CardProps>(({ isPrimaryContent, children, colSpans, rowSpans, className, ...props }, ref) => {
    const cardClasses = classnames('card', {
        "card--primary-content": isPrimaryContent
    }, getGridClasses(colSpans, rowSpans), className)
    return <div 
        className={cardClasses}
        {...props}
        ref={ref}>
            {children}
        </div>
});

export const CardBody: React.FC<CardBodyProps> = React.forwardRef<HTMLDivElement, CardBodyProps>(({ isPrimaryContent, children, className, ...props }, ref) => {
    const cardBodyClasses = classnames('card__body', {
        "card__body--primary-content": isPrimaryContent
    }, className);
    return <div
    className={cardBodyClasses}
    {...props}
    ref={ref}
    >
        {children}
    </div>
})

export const CardHeader: React.FC<CardHeaderProps> = React.forwardRef<HTMLDivElement, CardHeaderProps>(({ children, className, ...props }, ref) => {
    const cardBodyClasses = classnames('card__head', {

    }, className);
    return <div
    className={cardBodyClasses}
    {...props}
    ref={ref}
    >
        {children}
    </div>
})

export default Card;