import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";
import { HTMLAttributes } from "react";
import { IExtendableElement } from "../../../Base/Components";

export interface InlineNotificationProps
  extends IExtendableElement<HTMLAttributes<HTMLDivElement>> {
  type?: InlineNotificationType;
  title?: string;
  icon?: IconKeys | string | null;
  dismissable?: boolean;
  onDismissed?: () => void;
}

export enum InlineNotificationType {
  Info = "info",
  Success = "success",
  Warn = "warn",
  Error = "error",
}
