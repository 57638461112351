import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { AddressProps } from "./Address.types";
import Icon from "../../Icon/Icon";
import { IconKeys } from "@gbg/gbgcomponentlibrary/src/tokens/ts/_icons";
import { useThirdPartyScript } from "../../../Hooks/useThirdPartyScript";
import Spinner from "../../Spinner/Spinner";

export const Address = React.forwardRef<HTMLInputElement, AddressProps>(
  (
    {
      captureKey,
      options,
      fields,
      resultCallback,
      className,
      icon,
      error,
      disabled,
      placeholder,
      ...props
    }: AddressProps,
    ref
  ) => {
    const { loading: scriptLoading, error: scriptError } = useThirdPartyScript([
      "//services.postcodeanywhere.co.uk/css/address-3.91.css",
      "//services.postcodeanywhere.co.uk/js/address-3.91.js",
    ]);
    const [addressControl, setAddressControl] = useState<{
      destroy: () => {};
    } | null>(null);

    const outerRef = useRef<HTMLDivElement>();

    useEffect(() => {
      if (typeof window !== "undefined") {
        if (window["pca"] && outerRef) {
          console.log("ref", ref, outerRef);
          const input = outerRef.current.querySelector("input");
          const pca = window["pca"];
          let controlOptions = { key: captureKey, ...options };
          let controlFields = fields || [];
          controlFields.push({
            element: input,
            field: "",
            mode: pca.fieldMode.SEARCH,
          });
          let control = new window["pca"].Address(
            controlFields,
            controlOptions
          );
          setAddressControl(control);
          control.listen("populate", (data, alts, key) => {
            if (resultCallback) {
              resultCallback(data, alts, key);
            }
          });
          return () => {
            if (addressControl) {
              addressControl.destroy();
            }
          };
        }
      }
    }, [scriptLoading, captureKey]);

    if (scriptLoading) {
      return <Spinner />;
    }

    const addressClases = classnames(
      "control",
      "control--address-finder",
      {
        "control--disabled": disabled,
        "control--error": error,
      },
      className
    );
    return (
      <div ref={outerRef} className={addressClases}>
        <input
          {...props}
          type="text"
          placeholder={placeholder}
          disabled={disabled}
          ref={ref}
        ></input>
        {icon ? <Icon icon={icon} className={"text-g500"}></Icon> : null}
        {error ? (
          <Icon
            icon={IconKeys.AlertTriangle}
            className={"text-o600 icon--smaller"}
          ></Icon>
        ) : null}
      </div>
    );
  }
);

export default Address;
