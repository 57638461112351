import React from "react";
import { GridProps, GridSizes } from "./Grid.types";
import classnames from "classnames";

export const Grid: React.FC<GridProps> = React.forwardRef<
  HTMLDivElement,
  GridProps
>(({ children, size, ...props }, ref) => {
  console.warn(
    "Grid is deprecated. Please use CSS Grid directly in your project instead."
  );
  const gridClasses = classnames({
    "grid-adaptive": size === GridSizes.Adaptive,
    "grid-small": size === GridSizes.Small,
    "grid-medium": size === GridSizes.Medium,
    "grid-large": size === GridSizes.Large,
  });
  return (
    <div className={gridClasses} {...props} ref={ref}>
      {children}
    </div>
  );
});

export default Grid;
