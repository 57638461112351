import React from "react";
import { ConfirmDialogProps } from "./ConfirmDialog.types";
import { Modal, ModalHeader, ModalBody } from "../../Modal/Modal";
import AlertBar, { AlertBarText } from "../../AlertBar/AlertBar";
import Button from "../../../Atoms/Button/Button";
import { ButtonKind } from "../../../Atoms/Button/Button.types";

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  confirmButtonKind,
  confirmButtonText,
  cancelButtonText,
  onDismissed,
  children,
  isVisible,
  type,
  icon,
  title,
  text,
  ...props
}) => {
  return (
    <Modal {...props} isVisible={isVisible}>
      <ModalHeader>
        <AlertBar type={type} icon={icon}>
          <AlertBarText title={title} text={text} />
        </AlertBar>
      </ModalHeader>
      <ModalBody>
        {children}
        <Button
          kind={ButtonKind.Secondary}
          className="m-m-t-4 m-m-r-1"
          onClick={() => {
            onDismissed ? onDismissed(false) : null;
          }}
        >
          {cancelButtonText ?? "Cancel"}
        </Button>
        <Button
          kind={confirmButtonKind ?? ButtonKind.Primary}
          className="m-m-t-4 m-m-r-1"
          onClick={() => {
            onDismissed ? onDismissed(true) : null;
          }}
        >
          {confirmButtonText ?? "Confirm"}
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmDialog;
