import React, { FunctionComponent, useEffect, useRef } from "react";
import classnames from "classnames";
import { highlightCode } from "@gbg/gbgcomponentlibrary/src/_codeHighlighting";
import { CodeSampleProps } from "./CodeSample.types";

export const CodeSample: FunctionComponent<CodeSampleProps> = ({
  language,
  children,
  className,
  lineNumbers,
  ...props
}) => {
  const codeClasses = classnames(
    `language-${language}`,
    {
      "line-numbers": lineNumbers,
    },
    className
  );

  const preRef = useRef<HTMLPreElement>(null);

  useEffect(() => {
    preRef.current.className = "";
    highlightCode();
  }, [lineNumbers, language]);

  return (
    <pre {...props} ref={preRef}>
      <code className={codeClasses}>{children}</code>
    </pre>
  );
};

export default CodeSample;
