import React from "react";
import {
  GlobalNotificationProps,
  GlobalNotificationType,
} from "./GlobalNotification.types";
import classnames from "classnames";

export const GlobalNotification: React.FC<GlobalNotificationProps> = React.forwardRef<
  HTMLDivElement,
  GlobalNotificationProps
>(({ children, type, className, ...props }, ref) => {
  const globalNotificationClasses = classnames(
    "notification",
    "notification--global",
    {
      "notification--info": type == GlobalNotificationType.Info,
      "notification--success": type == GlobalNotificationType.Success,
      "notification--warn": type == GlobalNotificationType.Warn,
      "notification--error": type == GlobalNotificationType.Error,
    },
    className
  );
  return (
    <div className={globalNotificationClasses} {...props} ref={ref}>
      <div className="notification__content">{children}</div>
    </div>
  );
});

export default GlobalNotification;
