import React from "react";
import { AlertDialogProps } from "./AlertDialog.types";
import { Modal, ModalHeader, ModalBody } from "../../Modal/Modal";
import AlertBar, { AlertBarText } from "../../AlertBar/AlertBar";
import Button from "../../../Atoms/Button/Button";

export const AlertDialog: React.FC<AlertDialogProps> = ({
  buttonText,
  onDismissed,
  children,
  isVisible,
  type,
  icon,
  title,
  text,
  ...props
}) => {
  return (
    <Modal {...props} isVisible={isVisible}>
      <ModalHeader>
        <AlertBar type={type} icon={icon}>
          <AlertBarText title={title} text={text} />
        </AlertBar>
      </ModalHeader>
      <ModalBody>
        {children}
        <Button
          className="m-m-t-4 w-100"
          onClick={() => {
            onDismissed ? onDismissed() : null;
          }}
        >
          {buttonText ?? "Ok"}
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default AlertDialog;
